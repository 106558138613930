<template>
  <div>
    <div class="error-panel ht-100p mt-4">
      <div class="access-denied-icon tx-danger"><i class="fas fa-times-circle"></i></div>
      <h1 class="tx-28 tx-sm-36 tx-numeric tx-md-40 tx-semibold">Access   </h1>
      <h4 class="tx-16 tx-sm-18 tx-md-24 tx-light mg-b-20 mg-md-b-30">Oops. The page you were looking doesn't have Permission</h4>
      <p class="tx-12 tx-sm-13 tx-md-14 tx-color-04">Please Contact Your Admin. But you can click Button below to go back to Dashboard</p>

      <div class="">
        <a href="/"><button class="btn btn-primary" type="button">Dashboard</button></a>
      </div>
    </div><!-- error-panel -->
  </div>
  
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {};
  },
};
</script>
